
import { Options, Vue } from 'vue-class-component'
// import theHome from '@/components/Frontend/Home/index.vue'

@Options({
  // components: {
  //   theHome
  // }
})
export default class epayview extends Vue {}
